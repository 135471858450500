import {createTRPCReact, httpBatchLink} from '@trpc/react-query';
import {AppRouter} from 'daze-supabase/functions/trpc/router';
import config from './config';

export const trpc = createTRPCReact<AppRouter>();

export const createClient = (accessToken?: string) => {
    return trpc.createClient({
        transformer: {
            serialize(o) {
                return o;
            },
            deserialize(o) {
                return o;
            },
        },
        links: [
            httpBatchLink({
                url: `${config.supabaseFunctionsUrl}/trpc`,
                headers() {
                    return accessToken
                        ? {
                              Authorization: `Bearer ${accessToken}`,
                          }
                        : {};
                },
            }),
        ],
    });
};
