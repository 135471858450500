import {useContext, useEffect} from 'react';
import {SessionContext} from './App';
import {trpc} from './trpc';
import {supabase} from './supabase';

export default function Main() {
    const createChat = trpc.createChat.useMutation();
    const listChatsQuery = trpc.listChats.useQuery({});

    const session = useContext(SessionContext);

    const handleClickNewChatButton = () => {
        createChat.mutateAsync({name: 'new chat', memberIds: [session.user.id]});
    };

    useEffect(() => {
        const channel = supabase.channel('test');
        channel
            .on('presence', {event: 'sync'}, () => {
                const state = channel.presenceState();
                console.log('state sync', JSON.stringify(state));
            })
            .subscribe();
    }, []);

    return (
        <div className="main">
            {listChatsQuery.isLoading
                ? 'loading'
                : listChatsQuery.isSuccess &&
                  listChatsQuery.data.map((chat) => <div>{chat.id}</div>)}
            <button onClick={handleClickNewChatButton}>Create Chat</button>
        </div>
    );
}
