const config = {
    development: {
        supabaseFunctionsUrl: 'http://localhost:54321/functions/v1',
        supabaseApiUrl: 'http://localhost:54321',
        supabaseAnonKey:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImV4cCI6MTk4MzgxMjk5Nn0.EGIM96RAZx35lJzdJsyH-qQwv8Hdp7fsn3W0YpN81IU',
    },
    production: {
        supabaseFunctionsUrl: 'https://wthhgenqanuzcczmjics.functions.supabase.co',
        supabaseApiUrl: 'https://wthhgenqanuzcczmjics.supabase.co',
        supabaseAnonKey:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Ind0aGhnZW5xYW51emNjem1qaWNzIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzgyMTAyMzEsImV4cCI6MTk5Mzc4NjIzMX0.czCQFiTZRzffMM7I14RfQ5HWlxxvHz7hwgudBF00oug',
    },
};

export default config[getEnvKey()];

type ConfigKey = keyof typeof config;

function getEnvKey(): ConfigKey {
    const envSetting = process.env.REACT_APP_ENV;
    if (envSetting) {
        if (isValidKey(envSetting)) {
            return envSetting;
        } else {
            console.warn('Invalid REACT_APP_ENV:', envSetting);
            return 'development';
        }
    }
    return 'development';
}

function isValidKey(k?: string): k is ConfigKey {
    if (!k) return false;
    return ['development', 'production'].includes(k);
}
