import {Session} from '@supabase/supabase-js';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import React, {useEffect, useMemo, useState} from 'react';
import Auth from './Auth';
import Main from './Main';
import {supabase} from './supabase';
import {trpc, createClient} from './trpc';

export const SessionContext = React.createContext<Session>({} as Session);

export default function App() {
    const [session, setSession] = useState<Session | null>(null);
    const sessionRef = React.useRef(session);
    useEffect(() => {
        sessionRef.current = session;
    }, [session]);

    const [queryClient] = useState(() => new QueryClient());
    const trpcClient = useMemo(() => createClient(session?.access_token), [session]);

    useEffect(() => {
        supabase.auth.getSession().then(({data: {session}}) => {
            setSession(session);
        });

        supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
        });
    }, []);

    return (
        <div className="container" style={{padding: '50px 0 100px 0'}}>
            {!session ? (
                <Auth />
            ) : (
                <SessionContext.Provider value={session}>
                    <trpc.Provider client={trpcClient} queryClient={queryClient}>
                        <QueryClientProvider client={queryClient}>
                            <Main />
                        </QueryClientProvider>
                    </trpc.Provider>
                </SessionContext.Provider>
            )}
        </div>
    );
}
